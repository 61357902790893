
  import {shuffleArray} from '@/helper/ArrayHelper';

  export default {
    name: 'RelatedWikis',
    filters: {
      truncate(string, value) {
        return string.length > value
          ? string.substring(0, value) + '…'
          : string;
      },
    },
    props: {
      wiki: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        selectedItem: {},
        relatedWikis: [],
      };
    },
    async fetch() {
      const related_wiki_ids = [];

      if (this.wiki.relation_tags) {
        const relation_tags_structured = this.wiki.relation_tags
          .split(',')
          .map((x) => ['relation_tags_contains', x.trim()]);
        relation_tags_structured.push(['scope_contains', process.env.SCOPE]);

        const relatedWikisByRelationTag = await this.$strapi.find(
          'wikis',
          relation_tags_structured
        );

        relatedWikisByRelationTag.forEach((element) => {
          if (
            !related_wiki_ids.includes(element.id) &&
            element.id !== this.wiki.id
          ) {
            related_wiki_ids.push(element.id);
          }
        });
      }

      if (related_wiki_ids.length > 0) {
        //related_wiki_ids = shuffleArray(related_wiki_ids).slice(0, 5);

        const related_wikis_search_params = [
          ['scope_contains', process.env.SCOPE],
        ];
        related_wiki_ids.forEach((element) => {
          related_wikis_search_params.push(['id_in', element]);
        });

        this.relatedWikis = await this.$strapi.find(
          'wikis',
          related_wikis_search_params
        );
        this.relatedWikis = shuffleArray(this.relatedWikis).slice(0, 5);
      }
    },
  };
